import React from "react";
import "./index.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Error from "./Pages/Error/Error";
import Register from "./Pages/Register/Register";
import Login from "./Pages/Login/Login";
import GoToTop from "./Components/GoToTop/GoToTop";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import YourFiles from "./Pages/YourFiles/YourFiles";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import UploadedFile from "./Pages/UploadedFile/UploadedFile";
import CompProfile from "./Pages/CompProfile/CompProfile";
import Test from "./Pages/UploadFile/Test";
import ViewFile from "./Pages/ViewFile/ViewFile";
import Protected from "./Components/Protected/Protected";
import EditFile from "./Pages/EditFile/EditFile";
import Settings from "./Pages/Settings/Settings";
import Support from "./Pages/Support/Support";
import Privacypolicy from "./Pages/TearmsAndPolicy/Privacypolicy";
import Tearmsandservices from "./Pages/TearmsAndPolicy/Tearmsandservices";
import HomePage from "./Pages/HomePage/HomePage";
import SelectBucket from "./Pages/SelectBucket/SelectBucket";
import AddUser from "./Pages/AddUser/AddUser";
import UserList from "./Pages/CompProfile/UserList";
import ProgressBox from "./Components/ProgressBox/ProgressBox";
import ProgressBoxWIthRedux from "./Components/ProgressBox/ProgressBoxWIthRedux";
import Pricing from "./Pages/pricing/Pricing";
import Viewer from "./Components/Viewer/Viewer";
import OhifViewer from "./Pages/ViewFile/OhifViewer";

// remember me done --> ("/", "/signup", "/forgot-password", "/reset-password", "/upload-file", "/your-files", "/about-us", "/contact-us", "*")
const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/signin" element={<Login />} />
          <Route exact path="/signup" element={<Register />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/support" element={<Support />} />
          <Route exact path="/privacypolicy" element={<Privacypolicy />} />
          <Route exact path="/termsofservice" element={<Tearmsandservices />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route
            exact
            path="/upload-file"
            element={<Protected Component={Test} />}
          />
          <Route
            exact
            path="/uploaded-file"
            element={<Protected Component={UploadedFile} />}
          />
          <Route
            exact
            path="/my-files"
            element={<Protected Component={YourFiles} />}
          />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route
            exact
            path="/company-profile"
            element={<Protected Component={CompProfile} />}
          />
          <Route
            exact
            path="/view-file"
            element={<Protected Component={ViewFile} />}
          />
          <Route
            exact
            path="/edit-file"
            element={<Protected Component={EditFile} />}
          />
          <Route
            exact
            path="/settings"
            element={<Protected Component={Settings} />}
          />
          <Route
            exact
            path="/selectbucket"
            element={<Protected Component={SelectBucket} />}
          />
          <Route
            exact
            path="/adduser"
            element={<Protected Component={AddUser} />}
          />
          <Route
            exact
            path="/userList"
            element={<Protected Component={UserList} />}
          />
          <Route
            exact
            path="/viewer"
            element={<Protected Component={Viewer} />}
          />
          <Route
            exact
            path="/viewer/:studyInstanceUID"
            element={<Protected Component={OhifViewer} />}
          />
          <Route exact path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
      <GoToTop />
      <ProgressBoxWIthRedux />
    </div>
  );
};

export default App;
