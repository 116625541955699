import React from "react";
import Header from "../../Components/Header/Header";
import "../SelectBucket/SelectBucket.css";
import folders3 from "../../utils/images/folderimg.png";
import AtomImgActive from "../../utils/images/atom_1.png";
import sharefolderimg from "../../utils/images/sharefolderimg.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalConstants } from "../../utils/globalconstant";
import { CgCloseO } from "react-icons/cg";
import { Divider } from "@mui/material";
import axios from "axios";
import Cookie from "js-cookie";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import { partial } from "filesize";
import { successToast } from "../../utils/Files/toasterMsgs";
import { ToastContainer } from "react-toastify";
import ProgressBar from "@ramonak/react-progress-bar";

import {
  getAwsPublicPrivateData,
  // getFileCountAWS,
  getFileCountIPFS,
  // getFolderCountAWS,
  getFolderCountIPFS,
  // getStorageAWS,
  getStorageIPFS,
} from "../../redux/SelectBucketSlice";
import { getBucketData } from "../../redux/GetBucketdataSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader3 from "../../utils/Files/data";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function SelectBucket() {
  const atomS3Data = useSelector((state) => state.getBucketData.AtomS3Data);
  //variable
  var userID = sessionStorage.getItem("userID");
  var isOrg = sessionStorage.getItem("isorg");
  const size = partial({ base: 2, standard: "jedec" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const totalSizeAWSPublic = useSelector(
    (state) => state.selectBucketData.totalSizeAWSPublicData
  );
  const usedSizeAWSPublic = useSelector(
    (state) => state.selectBucketData.usedSizeAWSPublicData
  );
  const totalSizeAWSPrivate = useSelector(
    (state) => state.selectBucketData.totalSizeAWSPrivateData
  );
  const usedSizeAWSPrivate = useSelector(
    (state) => state.selectBucketData.usedSizeAWSPrivateData
  );
  const totalSizeIPFS = useSelector(
    (state) => state.selectBucketData.totalSizeIPFSData
  );
  const usedSizeIPFS = useSelector(
    (state) => state.selectBucketData.usedSizeIPFSData
  );
  const aWSpublicFoldersCount = useSelector(
    (state) => state.selectBucketData.awsPublicFolderCountData
  );
  const aWSpublicFilesCount = useSelector(
    (state) => state.selectBucketData.awsPublicFileCountData
  );
  const aWSprivateFoldersCount = useSelector(
    (state) => state.selectBucketData.awsPrivateFolderCountData
  );
  const aWSprivateFilesCount = useSelector(
    (state) => state.selectBucketData.awsPrivateFileCountData
  );
  const FolderCountIPFS = useSelector(
    (state) => state.selectBucketData.ipfsFolderCountData
  );
  const FileCountIPFS = useSelector(
    (state) => state.selectBucketData.ipfsFileCountData
  );
  const LoaderData = useSelector(
    (state) => state.selectBucketData.selectbucketLoder
  );

  //variable
  const [openPopup, setOpenPopup] = useState(false);
  const [selectRange, SetSelectRange] = useState("10 GB");
  const [selectRangeIPFS, SetSelectRangeIPFS] = useState("10 GB");
  const [checkAWS, setCheckAWS] = useState(false);
  const [checkIPFS, setCheckIPFS] = useState(false);
  const [allBucket, setAllBucket] = useState([]);
  const [awsBucketId, setAWSBucketId] = useState("");
  const [awsBucketName, setAwsBucketName] = useState("");
  const [errorPopup, setErrorPopup] = useState("");
  const [effect, setEffect] = useState(false);

  // const [totalSizeAWSPublic, setTotalSizeAWSPublic] = useState("");
  // const [usedSizeAWSPublic, setUsedSizeAWSPublic] = useState("");
  // const [aWSpublicFilesCount, setAWSpublicFilesCount] = useState("");
  // const [aWSpublicFoldersCount, setAWSpublicFoldersCount] = useState("");
  // const [totalSizeAWSPrivate, setTotalSizeAWSPrivate] = useState("");
  // const [usedSizeAWSPrivate, setUsedSizeAWSPrivate] = useState("");
  // const [aWSprivateFilesCount, setAWSprivateFilesCount] = useState("");
  // const [aWSprivateFoldersCount, setAWSprivateFoldersCount] = useState("");

  // const [usedSizeaws, setUsedSizeaws] = useState();
  const [storageAWSsize, setStorageAWSsize] = useState("");
  const [totalStorageAWS, setTotalStorageAWS] = useState("");
  const [storageIPFSsize, setStorageIPFSsize] = useState("");
  const [storageIPFStotalsize, setStorageIPFSTotalSize] = useState("");

  //language variable
  const [text_welcomeuser, setText_welcomeuser] = useState("Welcome User!");
  const [text_selectbucket, setText_selectbucket] = useState(
    "Select Amazon S3 Bucket"
  );
  const [text_companystorage, setText_sompanystorage] =
    useState("Company Storage");
  const [text_sharedwithme, setText_sharedwithme] = useState("Shared With Me");
  const [text_selstoragelocation, setText_selstoragelocation] = useState(
    "Select Storage Location"
  );
  const [text_atomstoragenetwork1, setText_atomstoragenetwork1] = useState(
    "Atom Storage Network"
  );
  const [text_amazons3, setText_amazons3] = useState("Amazon S3");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_managestorage, setText_managestorage] =
    useState("Manage Storage");
  const [text_selectbucket1, setText_selectbucket1] = useState("Select Bucket");
  const [text_selectrange, setText_selectrange] = useState("Select Range");
  const [text_atomstoragebucket, setText_atomstoragebucket] = useState(
    "Atom Storage Bucket"
  );
  const [text_mystorage, setText_mystorage] = useState("My Storage");
  const [text_awss3, setText_awss3] = useState("AWS S3");
  const [text_folder, setText_folder] = useState("Folder");
  const [text_folder_s, setText_folder_s] = useState("Folders");
  const [text_file, setText_File] = useState("File");
  const [text_File_s, set_text_File_s] = useState("Files");
  const [text_err_storage, setText_err_storage] = useState(
    "Please Select Any one Storage"
  );
  const [select_upload_location, setSelect_upload_location] = useState(
    "Please Select Upload Location"
  );
  useEffect(() => {
    if (allBucket.length > 0) {
      setAWSBucketId(allBucket[0].bucketId);
      setAwsBucketName(allBucket[0].bucketName);
    }
  }, [allBucket]);
  //*** Increase AWS and s3 Size*/
  const handleIncreaseSizeS3 = () => {
    console.log(allBucket.filter((e) => e.bucketId === awsBucketId));
    var FindBucket = allBucket.filter((e) => e.bucketId === awsBucketId);
    if (FindBucket.length > 0) {
      if (FindBucket[0]?.atom_bucket === false) {
        if (FindBucket[0]?.users_existing_bucket === false) {
          handleincsizeCraete(
            FindBucket[0]?.bucketName,
            FindBucket[0]?.bucketId
          );
        } else {
          handleincsizeOwn(FindBucket[0]?.bucketName, FindBucket[0]?.bucketId);
        }
      }
      if (FindBucket[0]?.atom_bucket === true) {
        handleincreaseSize(FindBucket[0]?.bucketName, FindBucket[0]?.bucketId);
      }
    }
  };

  // useEffect(() => {
  //   dispatch(getAwsPublicPrivateData());
  // }, []);
  // const getAwsPublicPrivateData = () => {
  //   let headerConfig = {
  //     headers: {
  //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //     },
  //   };
  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE + `/file/storage-aws-public?userId=${userID}`;

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then((res) => {
  //       console.log("read file res***", res.data);

  //       setTotalSizeAWSPublic(size(res.data?.publicTotalSize));
  //       setUsedSizeAWSPublic(size(res.data?.publicUploadedSize));
  //       setAWSpublicFilesCount(res.data?.publicFilesCount);
  //       setAWSpublicFoldersCount(res.data?.publicFoldersCount);
  //     })
  //     .catch((err) => {
  //       console.log("read file err***", err);
  //       if (err?.response?.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         navigate("/");
  //       }
  //     })
  //     .then(function () {});

  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE + `/file/storage-aws-private?userId=${userID}`;

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then((res) => {
  //       console.log("read file res***", res.data);

  //       setTotalSizeAWSPrivate(size(res.data?.privateTotalSize));
  //       setUsedSizeAWSPrivate(size(res.data?.privateUploadedSize));
  //       setAWSprivateFilesCount(res.data?.privateFilesCount);
  //       setAWSprivateFoldersCount(res.data?.privateFoldersCount);
  //     })
  //     .catch((err) => {
  //       console.log("read file err***", err);
  //       if (err?.response?.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         navigate("/");
  //       }
  //     })
  //     .then(function () {});
  // };

  useEffect(() => {
    if (
      totalSizeAWSPublic?.length === 0 &&
      usedSizeAWSPublic?.length === 0 &&
      totalSizeAWSPrivate?.length === 0 &&
      usedSizeAWSPrivate?.length === 0 &&
      aWSpublicFoldersCount === 0 &&
      aWSpublicFilesCount === 0 &&
      aWSprivateFoldersCount === 0 &&
      aWSprivateFilesCount === 0
    ) {
      dispatch(getAwsPublicPrivateData());
    }
  }, [
    1000,
    totalSizeAWSPublic?.length,
    usedSizeAWSPublic?.length,
    totalSizeAWSPrivate?.length,
    usedSizeAWSPrivate?.length,
    aWSpublicFoldersCount,
    aWSpublicFilesCount,
    aWSprivateFoldersCount,
    aWSprivateFilesCount,
  ]);
  useEffect(() => {
    if (totalSizeIPFS?.length === 0 && usedSizeIPFS?.length === 0) {
      dispatch(getStorageIPFS());
    }
  }, [1000, totalSizeIPFS?.length, usedSizeIPFS?.length]);
  // useEffect(() => {
  //   if (aWSpublicFoldersCount === 0) {
  //     console.log("awsFolderCount 1******", aWSpublicFoldersCount);
  //     dispatch(getFolderCountAWS());
  //     console.log("awsFolderCount 2******", aWSpublicFoldersCount);
  //   }
  // }, [aWSpublicFoldersCount]);
  // useEffect(() => {
  //   if (aWSpublicFilesCount === 0) {
  //     dispatch(getFileCountAWS());
  //   }
  // }, [aWSpublicFilesCount]);
  useEffect(() => {
    if (FolderCountIPFS === 0) {
      dispatch(getFolderCountIPFS());
    }
  }, [FolderCountIPFS]);
  useEffect(() => {
    if (FileCountIPFS === 0) {
      dispatch(getFileCountIPFS());
    }
  }, [FileCountIPFS]);

  const closePopup = () => {
    setOpenPopup(false);
    setCheckAWS(false);
    setCheckIPFS(false);
    setAllBucket([]);
    setAWSBucketId("");
    setAwsBucketName("");
    SetSelectRangeIPFS("10 GB");
    SetSelectRange("10 GB");
    setErrorPopup("");
  };
  //language
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_sharedwithme(
        xpath.select1("/resources/string[@name='text_sharedwithme']", doc)
          .firstChild.data
      );
      setText_welcomeuser(
        xpath.select1("/resources/string[@name='text_welcomeuser']", doc)
          .firstChild.data
      );
      setText_selectbucket(
        xpath.select1("/resources/string[@name='text_selectbucket']", doc)
          .firstChild.data
      );
      setText_sompanystorage(
        xpath.select1("/resources/string[@name='text_companystorage']", doc)
          .firstChild.data
      );

      setText_selstoragelocation(
        xpath.select1("/resources/string[@name='text_selstoragelocation']", doc)
          .firstChild.data
      );
      setText_atomstoragenetwork1(
        xpath.select1(
          "/resources/string[@name='text_atomstoragenetwork1']",
          doc
        ).firstChild.data
      );
      setText_amazons3(
        xpath.select1("/resources/string[@name='text_amazons3']", doc)
          .firstChild.data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_managestorage(
        xpath.select1("/resources/string[@name='text_managestorage']", doc)
          .firstChild.data
      );
      setText_selectbucket1(
        xpath.select1("/resources/string[@name='text_selectbucket1']", doc)
          .firstChild.data
      );
      setText_selectrange(
        xpath.select1("/resources/string[@name='text_selectrange']", doc)
          .firstChild.data
      );
      setText_atomstoragebucket(
        xpath.select1("/resources/string[@name='text_atomstoragebucket']", doc)
          .firstChild.data
      );
      setText_mystorage(
        xpath.select1("/resources/string[@name='text_mystorage']", doc)
          .firstChild.data
      );
      setText_awss3(
        xpath.select1("/resources/string[@name='text_awss3']", doc).firstChild
          .data
      );
      setText_folder(
        xpath.select1("/resources/string[@name='text_folder']", doc).firstChild
          .data
      );
      setText_folder_s(
        xpath.select1("/resources/string[@name='text_folder']", doc).firstChild
          .data
      );
      setText_File(
        xpath.select1("/resources/string[@name='text_file']", doc).firstChild
          .data
      );
      set_text_File_s(
        xpath.select1("/resources/string[@name='text_File_s']", doc).firstChild
          .data
      );
      setText_err_storage(
        xpath.select1("/resources/string[@name='text_err_storage']", doc)
          .firstChild.data
      );
      setSelect_upload_location(
        xpath.select1("/resources/string[@name='text_err_storage']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };

  useEffect(() => {
    if (atomS3Data?.length > 0 && effect === true) {
      getBucket();
    }
  }, [atomS3Data?.length]);
  const getBucket = () => {
    var Val = atomS3Data;
    if (Val.length > 0) {
      setAllBucket(Val);

      var userID = sessionStorage.getItem("userID");
      var data = {
        userId: userID,
        bucketName: Val[0].bucketName,
        bucketId: Val[0].bucketId,
        users_existing_bucket: Val[0].users_existing_bucket,
        atom_bucket: Val[0].atom_bucket,
      };
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file/storage-limit-aws`;
      axios
        .post(API_URL, data, headerConfig)
        .then((res) => {
          console.log("read file res***", res?.data);

          var Data = res?.data?.sizeInBytes;
          if (Data !== "") {
            var TotalusedStorage = Data;

            setStorageAWSsize(TotalusedStorage);
          }
          if (Val[0].users_existing_bucket === true) {
            var TotalStorage =
              res?.data?.total_storage_size?.aws_package_size_own;
            setTotalStorageAWS(TotalStorage);
          }
          if (Val[0].users_existing_bucket === false) {
            var TotalStorage =
              res?.data?.total_storage_size?.aws_package_size_created;
            setTotalStorageAWS(TotalStorage);
          }
          if (Val[0].atom_bucket === true) {
            console.log(size(res?.data?.total_size[0]?.pack_size));
            if (Val[0].bucketName === "atom-storage-private") {
              setTotalStorageAWS(res?.data?.total_size[1]?.pack_size);
            } else {
              setTotalStorageAWS(res?.data?.total_size[0]?.pack_size);
            }
          }
        })
        .catch((err) => {
          console.log("read file err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        })
        .then(function () {});
    }
  };
  //*** Select Bucket AWS*/
  const handleSelectBucket = (val) => {
    console.log(val.target.value);
    setAWSBucketId(val.target.value);
    var BucketFinalName = allBucket.filter(
      (e, i) => e.bucketId === val.target.value
    );
    console.log(BucketFinalName);
    if (BucketFinalName.length > 0) {
      setAwsBucketName(BucketFinalName[0].bucketName);
    }
    var userID = sessionStorage.getItem("userID");
    var data = {
      userId: userID,
      bucketName: BucketFinalName[0].bucketName,
      bucketId: BucketFinalName[0].bucketId,
      users_existing_bucket: BucketFinalName[0].users_existing_bucket,
      atom_bucket: BucketFinalName[0].atom_bucket,
    };
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file/storage-limit-aws`;
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("read file res***", res?.data);

        var Data = res?.data?.sizeInBytes;
        if (Data !== "") {
          var TotalusedStorage = Data;

          setStorageAWSsize(TotalusedStorage);
        }
        if (BucketFinalName[0].users_existing_bucket === true) {
          var TotalStorage =
            res?.data?.total_storage_size?.aws_package_size_own;
          setTotalStorageAWS(TotalStorage);
        }
        if (BucketFinalName[0].users_existing_bucket === false) {
          var TotalStorage =
            res?.data?.total_storage_size?.aws_package_size_created;
          setTotalStorageAWS(TotalStorage);
        }
        if (BucketFinalName[0].atom_bucket === true) {
          console.log(size(res?.data?.total_size[0]?.pack_size));
          if (BucketFinalName[0].bucketName === "atom-storage-private") {
            setTotalStorageAWS(res?.data?.total_size[1]?.pack_size);
          } else {
            setTotalStorageAWS(res?.data?.total_size[0]?.pack_size);
          }
        }
      })
      .catch((err) => {
        console.log("read file err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  const handleincreaseSize = (bucketName, bucketId) => {
    var sizeinbyte =
      selectRange === "10 GB"
        ? 10737418240
        : selectRange === "20 GB"
        ? 21474836480
        : selectRange === "30 GB"
        ? 32212254720
        : selectRange === "40 GB"
        ? 42949672960
        : selectRange === "50 GB"
        ? 53687091200
        : selectRange === "60 GB"
        ? 64424509440
        : selectRange === "70 GB"
        ? 75161927680
        : selectRange === "80 GB"
        ? 85899345920
        : selectRange === "90 GB"
        ? 96636764160
        : selectRange === "100 GB"
        ? 107374182400
        : "";
    var data = {
      userid: userID,
      bucketname: bucketName,
      bucketId: bucketId,
      newStorageSizeAtom: sizeinbyte,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/increase-storage-aws-atom";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Increase size res***", res);
        if (res?.status === 200) {
          successToast(res?.data?.message);
          // dispatch(getStorageAWS());
          dispatch(getAwsPublicPrivateData());
          closePopup();
        }
      })
      .catch((err) => {
        console.log("Increase size err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  //*** Increase AWS Size*/
  const handleincsizeCraete = (bucketName, bucketId) => {
    var sizeinbyte =
      selectRange === "10 GB"
        ? 10737418240
        : selectRange === "20 GB"
        ? 21474836480
        : selectRange === "30 GB"
        ? 32212254720
        : selectRange === "40 GB"
        ? 42949672960
        : selectRange === "50 GB"
        ? 53687091200
        : selectRange === "60 GB"
        ? 64424509440
        : selectRange === "70 GB"
        ? 75161927680
        : selectRange === "80 GB"
        ? 85899345920
        : selectRange === "90 GB"
        ? 96636764160
        : selectRange === "100 GB"
        ? 107374182400
        : "";
    var data = {
      userId: userID,
      bucketName: bucketName,
      bucketId: bucketId,
      newAwsStorageSize: sizeinbyte,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/increase-storage-aws-created";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Increase size res***", res);
        if (res?.status === 200) {
          successToast(res?.data?.message);
          // dispatch(getStorageAWS());
          dispatch(getAwsPublicPrivateData());
          closePopup();
        }
      })
      .catch((err) => {
        console.log("Increase size err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  //*** Increase AWS OWN Size*/
  const handleincsizeOwn = (bucketName, bucketId) => {
    var sizeinbyte =
      selectRange === "10 GB"
        ? 10737418240
        : selectRange === "20 GB"
        ? 21474836480
        : selectRange === "30 GB"
        ? 32212254720
        : selectRange === "40 GB"
        ? 42949672960
        : selectRange === "50 GB"
        ? 53687091200
        : selectRange === "60 GB"
        ? 64424509440
        : selectRange === "70 GB"
        ? 75161927680
        : selectRange === "80 GB"
        ? 85899345920
        : selectRange === "90 GB"
        ? 96636764160
        : selectRange === "100 GB"
        ? 107374182400
        : "";
    var data = {
      userId: userID,
      bucketName: bucketName,
      bucketId: bucketId,
      newAwsStorageSize: sizeinbyte,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/increase-storage-aws-own";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Increase size res***", res);
        if (res?.status === 200) {
          successToast(res?.data?.message);
          // dispatch(getStorageAWS());
          dispatch(getAwsPublicPrivateData());
          closePopup();
        }
      })
      .catch((err) => {
        console.log("Increase size err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  //*** Increase IPFS Size*/
  const handleincreaseSizeIPFS = () => {
    var sizeinbyte =
      selectRangeIPFS === "10 GB"
        ? 10737418240
        : selectRangeIPFS === "20 GB"
        ? 21474836480
        : selectRangeIPFS === "30 GB"
        ? 32212254720
        : selectRangeIPFS === "40 GB"
        ? 42949672960
        : selectRangeIPFS === "50 GB"
        ? 53687091200
        : selectRangeIPFS === "60 GB"
        ? 64424509440
        : selectRangeIPFS === "70 GB"
        ? 75161927680
        : selectRangeIPFS === "80 GB"
        ? 85899345920
        : selectRangeIPFS === "90 GB"
        ? 96636764160
        : selectRangeIPFS === "100 GB"
        ? 107374182400
        : "";
    var data = {
      userid: userID,
      ipfs_package_size: sizeinbyte,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/increase-storage-ipfs";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Increase size res***", res);
        if (res?.status === 200) {
          successToast(res?.data?.message);
          dispatch(getStorageIPFS());
          closePopup();
        }
      })
      .catch((err) => {
        console.log("Increase size err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };

  const getStorageLimitIPFS = (bucketname) => {
    var userID = sessionStorage.getItem("userID");
    console.log("awsBucketName in api call***", bucketname);
    var data = { userId: userID, bucketName: bucketname };
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file/storage-limit-ipfs`;

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("read file res***", res?.data?.sizeInBytes);
        var Value = res?.data?.sizeInBytes;
        if (Value.length === 0) {
          setStorageIPFSTotalSize(res?.data?.total_size);
        } else {
          var Data = res?.data?.sizeInBytes[0]?.totalSize;
          setStorageIPFSsize(Data);
          setStorageIPFSTotalSize(res?.data?.total_size);
        }
      })
      .catch((err) => {
        console.log("read file err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  return (
    <>
      <div>
        <Header />
        <div className="set-mm"></div>

        <div className="sb-main">
          <div className={openPopup ? "bgblur " : ""}>
            <div className="btn-storage mt-5">
              <div className="user-text text-center">
                <h1 className="text-center mt-3">{text_welcomeuser}</h1>
                <p className="text-center">{select_upload_location}</p>
              </div>
              <div className="btn-manage text-end">
                <button
                  className="manage-btn"
                  onClick={() => [
                    setOpenPopup(!openPopup),
                    // atomS3Data?.length === 0
                    //   ? dispatch(getBucketData())
                    //   : getBucket(),
                  ]}
                >
                  {text_managestorage}
                </button>
              </div>
            </div>
            {LoaderData === true ? (
              <>
                <div className="text-center mt-5">
                  <Loader3 />
                </div>
              </>
            ) : (
              <div>
                <div className="d-flex justify-content-center gap-5 flex-wrap mt-5 ">
                  <div
                    className="card wallet mt-3"
                    onClick={() => {
                      navigate("/my-files", {
                        state: { selectedTab: 1 },
                      });
                    }}
                  >
                    <div className="overlay"></div>
                    <div className="circle">
                      <img src={folders3} className="imag-folder" alt="" />
                    </div>
                    <h6 className="tiles text-center mt-3">
                      {isOrg === "true" ? (
                        <>{text_companystorage}</>
                      ) : (
                        <>{text_mystorage}</>
                      )}
                    </h6>
                    <span className="tiles">{text_awss3}</span>
                    <span className="tiles">
                      {usedSizeAWSPublic}/{totalSizeAWSPublic} (public)
                    </span>
                    <span className="tiles text-center">
                      ({aWSpublicFoldersCount}{" "}
                      {aWSpublicFoldersCount < 2 ? text_folder : text_folder_s},{" "}
                      {aWSpublicFilesCount}{" "}
                      {aWSpublicFilesCount < 2 ? text_file : text_File_s})
                    </span>
                    <span className="tiles">
                      {usedSizeAWSPrivate}/{totalSizeAWSPrivate} (private)
                    </span>
                    <span className="tiles text-center">
                      ({aWSprivateFoldersCount}{" "}
                      {aWSprivateFoldersCount < 2 ? text_folder : text_folder_s}
                      , {aWSprivateFilesCount}{" "}
                      {aWSprivateFilesCount < 2 ? text_file : text_File_s})
                    </span>
                  </div>
                  <div
                    className="card wallet mt-3"
                    onClick={() => {
                      navigate("/my-files", {
                        state: { selectedTab: 0 },
                      });
                    }}
                  >
                    <div className="overlay"></div>
                    <div className="circle">
                      <img src={AtomImgActive} alt="" className="imag-folder" />
                    </div>
                    <h6 className="tiles text-center mt-3">
                      {isOrg === "true" ? (
                        <>{text_companystorage}</>
                      ) : (
                        <>{text_mystorage}</>
                      )}
                    </h6>
                    <span className="tiles text-center">
                      {text_atomstoragenetwork1}
                    </span>
                    <span className="tiles">
                      {usedSizeIPFS}/{totalSizeIPFS}
                    </span>
                    <span className="tiles text-center">
                      ({FolderCountIPFS}{" "}
                      {FolderCountIPFS < 2 ? text_folder : text_folder_s},
                      {FileCountIPFS}{" "}
                      {FileCountIPFS < 1 ? text_file : text_File_s})
                    </span>
                  </div>
                  <div
                    className="card wallet mt-3"
                    onClick={() => {
                      navigate("/my-files", {
                        state: { selectedTab: 3 },
                      });
                    }}
                  >
                    <div className="overlay-share"></div>
                    <div className="circle share-circle mb-3">
                      <img
                        src={sharefolderimg}
                        className="imag-folder"
                        alt=""
                      />
                    </div>
                    <h6 className="tiles">{text_sharedwithme}</h6>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/****** Manage storage popup **********/}
      {openPopup === true && (
        <>
          <div className="popupaccess bucket-popup popup-content">
            <div className="p-4">
              <div className="d-flex justify-content-between">
                <h5 className="mt-1">{text_managestorage}</h5>
                <h3 className="">
                  <CgCloseO onClick={closePopup} />
                </h3>
              </div>
              <Divider />
              <h5 className="text-center mt-3">{text_selstoragelocation}</h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="gap-2 d-flex responsive-amazonbucket">
                    <input
                      type="checkbox"
                      name="amazon-s3"
                      id="amazon-s3"
                      className="mt-1 checkboxSty"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setCheckAWS(e.target.checked);
                          setErrorPopup("");
                          if (atomS3Data?.length === 0) {
                            setEffect(true);
                            dispatch(getBucketData());
                          } else {
                            getBucket();
                          }
                        } else {
                          setCheckAWS(false);
                        }
                      }}
                      checked={checkAWS}
                    />
                    <label for="amazon-s3" className="com-aws-text">
                      {text_amazons3}
                    </label>
                  </div>
                  {checkAWS ? (
                    <>
                      <h6 className="text-center responsive-popup-space"></h6>
                      <div className="d-flex gap-2 flex-wrap">
                        <p className="mt-2 bucket-font">{text_selectbucket1}</p>
                        <div className="select-bucket-com justify-content-center mt-1">
                          <select
                            className="uf-inputs w-100 uploadselectdrop-select"
                            onChange={(e) => [handleSelectBucket(e)]}
                            value={awsBucketId}
                          >
                            {allBucket.length > 0 ? (
                              <>
                                <option disabled>{text_selectbucket}</option>
                                {allBucket.map((v, i) => {
                                  return (
                                    <>
                                      <option value={v.bucketId}>
                                        {v.bucketName === "atom-storage"
                                          ? `${sessionStorage.getItem(
                                              "fName"
                                            )}'s Bucket`
                                          : v.bucketName ===
                                            "atom-storage-private"
                                          ? `${sessionStorage.getItem(
                                              "fName"
                                            )}'s Bucket`
                                          : v.bucketName}
                                        ({v.bucketACLOption})
                                      </option>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                <option disabled selected>
                                  {text_selectbucket}
                                </option>
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="">
                        <ProgressBar
                          className="mt-3 mb-3"
                          completed={storageAWSsize}
                          bgColor="#008A82"
                          height="10px"
                          width="77%"
                          isLabelVisible={false}
                          labelColor="#1a91ec"
                          maxCompleted={totalStorageAWS}
                        />
                        <p style={{ marginTop: "-11px" }}>
                          {size(storageAWSsize)} of {size(totalStorageAWS)} used
                        </p>
                      </div>

                      <div className="d-flex  flex-wrap">
                        <p className="mt-2 bucket-font">
                          {text_selectrange}&nbsp;
                        </p>
                        <br />
                        <div className="select-bucket-com justify-content-center mt-1">
                          <select
                            className="uf-inputs uploadselectdrop-select"
                            onChange={(e) => SetSelectRange(e.target.value)}
                            value={selectRange}
                          >
                            <option disabled>{text_selectrange}</option>
                            <option value="10 GB">10 GB</option>
                            <option value="20 GB">20 GB</option>
                            <option value="30 GB">30 GB</option>
                            <option value="40 GB">40 GB</option>
                            <option value="50 GB">50 GB</option>
                            <option value="60 GB">60 GB</option>
                            <option value="70 GB">70 GB</option>
                            <option value="80 GB">80 GB</option>
                            <option value="90 GB">90 GB</option>
                            <option value="100 GB">100 GB</option>
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6">
                  <div className="gap-2 d-flex responsive-amazonbucket">
                    <input
                      type="checkbox"
                      name="amazon-s3"
                      id="amazon-s3"
                      className="mt-1 checkboxSty"
                      checked={checkIPFS}
                      onChange={(e) => {
                        const isChecked = e.target.checked;

                        if (isChecked === true) {
                          setCheckIPFS(isChecked);
                          setErrorPopup("");
                          getStorageLimitIPFS("atom-storage");
                        } else {
                          setCheckIPFS(false);
                        }
                      }}
                    />
                    <label for="amazon-s3" className="com-aws-text">
                      {text_atomstoragenetwork1}
                    </label>
                  </div>
                  {checkIPFS === true ? (
                    <>
                      <h6 className=" mt-2">{text_atomstoragebucket}</h6>
                      <>
                        <div className="">
                          <ProgressBar
                            className="mt-3 mb-3"
                            completed={storageIPFSsize}
                            bgColor="#008A82"
                            height="10px"
                            width="72%"
                            isLabelVisible={false}
                            labelColor="#1a91ec"
                            maxCompleted={storageIPFStotalsize}
                          />
                          <p style={{ marginTop: "-11px" }}>
                            {size(storageIPFSsize)} of{" "}
                            {size(storageIPFStotalsize)} used
                          </p>
                        </div>
                      </>
                      <div className="d-flex  flex-wrap">
                        <p className="mt-4 bucket-font">
                          {text_selectrange}&nbsp;
                        </p>
                        <div className="select-bucket-com justify-content-center mt-3">
                          <select
                            className="uf-inputs uploadselectdrop-select"
                            onChange={(e) => [
                              SetSelectRangeIPFS(e.target.value),
                            ]}
                            value={selectRangeIPFS}
                          >
                            <option disabled>{text_selectrange}</option>
                            <option value="10 GB">10 GB</option>
                            <option value="20 GB">20 GB</option>
                            <option value="30 GB">30 GB</option>
                            <option value="40 GB">40 GB</option>
                            <option value="50 GB">50 GB</option>
                            <option value="60 GB">60 GB</option>
                            <option value="70 GB">70 GB</option>
                            <option value="80 GB">80 GB</option>
                            <option value="90 GB">90 GB</option>
                            <option value="100 GB">100 GB</option>
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <p className="errMsg mt-2 text-center">{errorPopup}</p>
              <Divider className="mt-4" />
            </div>

            <div className="d-flex justify-content-center gap-3 mt-2 mb-4">
              <button className="box-btn" onClick={closePopup}>
                {text_cancel}
              </button>
              {console.log(size(10737418240))}
              <button
                className="box-btn-save"
                onClick={() => [
                  checkAWS === true && checkIPFS === true
                    ? [handleIncreaseSizeS3(), handleincreaseSizeIPFS()]
                    : checkAWS === true
                    ? handleIncreaseSizeS3()
                    : checkIPFS === true
                    ? handleincreaseSizeIPFS()
                    : setErrorPopup(<>*{text_err_storage}!</>),
                ]}
              >
                {text_save}
              </button>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
}

export default SelectBucket;
