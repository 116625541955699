import React, { useEffect, useState } from "react";
import { installViewer } from "@ohif/viewer";
import { useParams } from "react-router-dom";
export const Viewer = () => {
  const [error, setError] = useState(null);
  const { studyInstanceUID } = useParams();
  const ohifViewerConfig = {
    servers: {
      dicomWeb: [
        {
          name: "Orthanc Server",
          wadoUriRoot: "https://ohifviewer.moramba.io:8282/wado",
          qidoRoot: "https://ohifviewer.moramba.io:8282/dicom-web",
          wadoRoot: "https://ohifviewer.moramba.io:8282/dicom-web",
          qidoSupportsIncludeField: true,
          imageRendering: "wadors",
          thumbnailRendering: "wadors",
          enableStudyLazyLoad: true,
        },
      ],
    },
    studyInstanceUIDs: [studyInstanceUID],
  };

  const containerId = "ohif";

  const componentRenderedOrUpdatedCallback = function () {
    console.log("OHIF Viewer rendered/updated");
  };

  useEffect(() => {
    const initViewer = async () => {
      try {
        await installViewer(
          ohifViewerConfig,
          containerId,
          componentRenderedOrUpdatedCallback
        );
      } catch (err) {
        console.error("Error initializing OHIF Viewer:", err);
        setError(
          err.message || "An error occurred while initializing the viewer"
        );
      }
    };

    initViewer();
  }, [studyInstanceUID]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div id={containerId} style={{ width: "100%", height: "100vh" }}></div>
  );
};

export default function ohifViewer() {
  return (
    <div className=" bg-dark ">
      <Viewer />
    </div>
  );
}
